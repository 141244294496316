<template>
  <div>
    <validation-provider
      #default="validationContext"
      :name="name"
      :rules="validations"
    >
      <b-form-group :label="label" :label-for="name">
        <cleave
          :id="id"
          class="form-control"
          v-model="content"
          :raw="true"
          v-b-tooltip.hover.topleft="tooltip != null ? tooltip : ''"
          :options="cleave"
          :placeholder="placeholder"
          :state="getValidationState(validationContext)"
        />
        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  VBTooltip,
  BFormTextarea,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, amount, email, integer, alphaNum } from "@validations";
import { ref, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
export default {
  props: [
    "name",
    "tooltip",
    "type",
    "valuex",
    "keys",
    "label",
    "options",
    "id",
    "validations",
    "placeholder",
    "cleave",
  ],
  components: {
    BCol,
    BCard,
    Cleave,
    BRow,
    BForm,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      refFormObserver,
      getValidationState,
    };
  },
  data() {
    return {
      required,
      amount,
      email,
      alphaNum,
      integer,
      content: this.valuex,
    };
  },
  mounted() {
    this.$watch(
      "$props",
      () => {
        this.content = this.valuex;
      },
      { deep: true }
    );
    this.changevalue(this.valuex, 1);
  },
  watch: {
    content(old, to) {
      this.changevalue(this.content);
    },
  },
  methods: {
    changevaluex() {

      this.changevalue(this.content);
    },
    changevalue(content, type = 0) {
      if (content != null) {
        this.$emit("updatedata", content);
        if (type) {
          this.content = content;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
